import { PageOptions } from '@graphcommerce/framer-next-pages'
import { hygraphPageContent, HygraphPagesQuery } from '@graphcommerce/graphcms-ui'
import { redirectOrNotFound, StoreConfigDocument } from '@graphcommerce/magento-store'
import {
  PageMeta,
  BlogTitle,
  GetStaticProps,
  LayoutTitle,
  LayoutHeader,
  storefrontConfig,
} from '@graphcommerce/next-ui'
import { Container } from '@mui/material'
import { GetStaticPaths } from 'next'
import {
  BlogAuthor,
  BlogList,
  BlogListDocument,
  BlogListQuery,
  BlogPostPathsDocument,
  BlogTags,
  LayoutDocument,
  LayoutNavigation,
  LayoutNavigationProps,
  RowRenderer,
} from '../../components'
import { getProductKeys } from '../../components/GraphCMS/RowProduct/GetProductKeys'
import { getProducts, getUrlKeys } from '../../components/GraphCMS/RowProduct/GetProducts'
import { RowProductDataQuery } from '../../graphql/RowProductData.gql'
import { graphqlSharedClient, graphqlSsrClient } from '../../lib/graphql/graphqlSsrClient'

type Props = HygraphPagesQuery & BlogListQuery & RowProductDataQuery
type RouteProps = { url: string }
type GetPageStaticPaths = GetStaticPaths<RouteProps>
type GetPageStaticProps = GetStaticProps<LayoutNavigationProps, Props, RouteProps>

function BlogPage(props: Props) {
  const { blogPosts, pages, products } = props
  const defaultPage = pages[0]
  const title = defaultPage.title ?? ''

  const filteredProducts = products?.items?.filter((item) => item?.stock_status === 'IN_STOCK')

  const page: NonNullable<typeof pages>[0] = {
    ...defaultPage,
    content: [
      ...defaultPage.content.map((c) =>
        c.__typename === 'RowProduct'
          ? {
              ...c,
              items: getProductKeys({ items: filteredProducts, urlKeys: c.productUrlKeys }),
            }
          : c,
      ),
    ],
  }

  return (
    <>
      <LayoutHeader floatingMd>
        <LayoutTitle size='small' component='span'>
          {title}
        </LayoutTitle>
      </LayoutHeader>

      <PageMeta title={title} metaDescription={title} canonical={`/${page.url}`} />
      <BlogTitle sx={{ maxWidth: 'none' }}>{title}</BlogTitle>
      {page.author ? <BlogAuthor author={page.author} date={page.date} /> : null}
      <Container
        sx={(theme) => ({
          [theme.breakpoints.up('md')]: {
            '& .SidebarSlider-root, .ContainerWithHeader-root': {
              maxWidth: theme.breakpoints.values.md,
            },
          },
        })}
      >
        <RowRenderer {...page} />
      </Container>
      <BlogTags relatedPages={page.relatedPages} />
      <BlogList blogPosts={blogPosts} />
    </>
  )
}

BlogPage.pageOptions = {
  Layout: LayoutNavigation,
} as PageOptions

export default BlogPage

export const getStaticPaths: GetPageStaticPaths = async ({ locales = [] }) => {
  if (import.meta.graphCommerce.limitSsg) return { paths: [], fallback: 'blocking' }

  const responses = locales.map(async (locale) => {
    const staticClient = graphqlSsrClient(locale)
    const BlogPostPaths = staticClient.query({
      query: BlogPostPathsDocument,
      variables: {
        scopes: storefrontConfig(locale)?.hygraphScopes,
      },
    })
    const { pages } = (await BlogPostPaths).data

    return (
      pages.map((page) => ({ params: { url: `${page?.url}`.replace('blog/', '') }, locale })) ?? []
    )
  })
  const paths = (await Promise.all(responses)).flat(1)
  return { paths, fallback: 'blocking' }
}

export const getStaticProps: GetPageStaticProps = async ({ locale, params }) => {
  const urlKey = params?.url ?? '??'

  const client = graphqlSharedClient(locale)
  const staticClient = graphqlSsrClient(locale)
  const limit = 4
  const conf = client.query({ query: StoreConfigDocument })

  const page = hygraphPageContent(staticClient, `blog/${urlKey}`)
  const layout = staticClient.query({
    query: LayoutDocument,
    fetchPolicy: 'cache-first',
    variables: {
      scopes: storefrontConfig(locale)?.hygraphScopes,
    },
  })

  const blogPosts = staticClient.query({
    query: BlogListDocument,
    variables: {
      scopes: storefrontConfig(locale)?.hygraphScopes,
      currentUrl: [`blog/${urlKey}`],
      first: limit,
    },
  })

  if (!(await page).data.pages?.[0])
    return redirectOrNotFound(staticClient, conf, { url: `blog/${urlKey}` }, locale)

  const url_keys = await getUrlKeys((await page).data)
  const products = await getProducts(locale, url_keys)

  return {
    props: {
      ...(await page).data,
      ...(await blogPosts).data,
      ...(await layout).data,
      ...products,
      up: { href: '/', title: 'Home' },
      apolloState: await conf.then(() => client.cache.extract()),
    },
    revalidate: 60 * 20,
  }
}
