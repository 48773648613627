import { BlogItemGrid } from '@graphcommerce/next-ui'
import { BlogItem } from './BlogItem'
import { BlogListQuery } from './BlogList.gql'

type BlogListProps = BlogListQuery

export function BlogList(props: BlogListProps) {
  const { blogPosts } = props

  // Sort the array based on the `pinned` property
  const sortedBlogPosts = [...blogPosts].sort((a, b) => {
    const sorted = new Date(b.date as string).getTime() - new Date(a.date as string).getTime()

    if (a.pinned) {
      if (!b.pinned || !b.date) {
        return -1
      }
      if (!a.date) {
        return 1
      }
      if (Number.isInteger(sorted)) return sorted
      return -1
    }
    if (b.pinned) {
      return 1
    }
    if (!b.date) {
      return -1
    }
    if (!a.date) {
      return 1
    }
    if (Number.isInteger(sorted)) return sorted
    return -1
  })

  return (
    <BlogItemGrid>
      {sortedBlogPosts.map((blogPost) => (
        <BlogItem key={blogPost.title} {...blogPost} />
      ))}
    </BlogItemGrid>
  )
}
