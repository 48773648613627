import { RowProductDataQuery } from '../../../graphql/RowProductData.gql'

type GetProductKeysProps = {
  items: NonNullable<RowProductDataQuery['products']>['items']
  urlKeys: string[]
}

export function getProductKeys(props: GetProductKeysProps) {
  const { items, urlKeys } = props
  return items?.filter((item) =>
    item?.url_key ? urlKeys.some((key) => key === item.url_key) : false,
  )
}
